@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=1bc03b04-168e-4b6c-987b-255497d5b582&fontids=5167167,5317098");

@font-face {
    font-family: "Bambino W05 Regular";
    src:
        url("./assets/fonts/5167167/413cc05d-38db-4d3c-8a8d-351aac6f633a.eot?#iefix");
    src:
        url("./assets/fonts/5167167/413cc05d-38db-4d3c-8a8d-351aac6f633a.eot?#iefix") format("eot"),
        url("./assets/fonts/5167167/ca075ab8-7f19-4a44-9bdc-770dd6b21fc9.woff2") format("woff2"),
        url("./assets/fonts/5167167/90800040-efcb-4c44-bbce-f4f5c871e397.woff") format("woff"),
        url("./assets/fonts/5167167/bc3b8c78-bb52-4907-ba72-db1fb25ac915.ttf") format("truetype");
}

@font-face {
    font-family: "Bambino W05 Black";
    src:
        url("./assets/fonts/5317098/3a750f83-a8d8-4872-bbc7-0c5884dbf9ad.eot?#iefix");
    src:
        url("./assets/fonts/5317098/3a750f83-a8d8-4872-bbc7-0c5884dbf9ad.eot?#iefix") format("eot"),
        url("./assets/fonts/5317098/b725a556-0c21-4299-98ae-60da8449acd3.woff2") format("woff2"),
        url("./assets/fonts/5317098/3384a86e-405f-49fa-8321-a4010beacef2.woff") format("woff"),
        url("./assets/fonts/5317098/6e9b4db6-48f5-448e-be4f-9f7e171dbebb.ttf") format("truetype");
}

@font-face{
    font-family: "OverpassMono-Regular";
    src:
        url("./assets/fonts/OverpassMono-Regular.ttf") format("truetype");
}